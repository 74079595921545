import { parseToRgba } from "color2k";
import { white } from "../common";
import { gray } from "../gray";
import { ColorScale } from "../types";

type RGBA = {
  alpha: number;
  blue: number;
  green: number;
  red: number;
};

function getLuminance(rgba: RGBA): number {
  const { blue, green, red } = rgba;
  const a: number[] = [red, green, blue].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function getContrastRatio(color1: string, color2: string): number {
  const rgba1 = arrayToRGBA(parseToRgba(color1));
  const rgba2 = arrayToRGBA(parseToRgba(color2));
  const luminance1 = getLuminance(rgba1);
  const luminance2 = getLuminance(rgba2);
  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);
  return (brightest + 0.05) / (darkest + 0.05);
}

function arrayToRGBA(rgbaArray: [number, number, number, number]): RGBA {
  return {
    red: rgbaArray[0],
    green: rgbaArray[1],
    blue: rgbaArray[2],
    alpha: rgbaArray[3],
  };
}

export function getForegroundColor(baseColor: string): string {
  const foregroundDark = gray["900"];
  const foregroundLight = white;
  const contrast1 = getContrastRatio(baseColor, foregroundDark);
  const contrast2 = getContrastRatio(baseColor, foregroundLight);
  return contrast1 > contrast2 ? foregroundDark : foregroundLight;
}

/**
 *
 * @param colorScale
 * @returns Returns light or dark foreground color for best contrast depending on the given color.
 */
export function generateForegroundColorObject(
  colorScale: ColorScale,
): Omit<ColorScale, "string"> {
  if (typeof colorScale === "string") {
    throw new Error(
      "[generateForegroundColorObject] colorScale must be an object",
    );
  }

  let result: Omit<ColorScale, "foreground"> = {};

  const colorScaleObj = { ...colorScale };
  delete colorScaleObj.foreground;

  Object.entries(colorScaleObj).forEach(([key, value]) => {
    if (!value) {
      console.error(`key ${key} is not defined in colorScaleObj`);
      return;
    } else if (typeof value !== "string") {
      console.error(`key ${key} must be a string`);
      return;
    }

    const name = key === "DEFAULT" ? "foreground" : `foreground-${key}`;

    result = {
      ...result,
      [name]: getForegroundColor(value),
    };
  });

  return result;
}
