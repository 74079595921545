import { tv, VariantProps } from "@nextui-org/react";
import { AriaToastProps, ToastAria, useToast } from "@react-aria/toast";
import { ToastState } from "@react-stately/toast";
import { DOMProps } from "@react-types/shared";
import { NotificationType, OmitRef } from "core/types";
import {
  CheckCircleIcon,
  CircleSlashIcon,
  CloseIcon,
  InfoIcon,
} from "ds/icons";
import { Button, ButtonProps } from "ds/ui";
import { ElementType, useRef } from "react";

export type ToastNotificationProps<T> = AriaToastProps<T> & {
  state: ToastState<T>;
};

type NotificationVariant = VariantProps<typeof notificationVariants>;
type NotificationColor = Exclude<NotificationVariant["color"], undefined>;

type BaseNotificationContentType = DOMProps & {
  color: NotificationColor;
  message: string;
  onClose?: () => void;
  title: string;
  type: NotificationType;
};

type WithActions = {
  actionLeft: OmitRef<ButtonProps>;
  actionRight: OmitRef<ButtonProps>;
};

export type NotificationContentType =
  | (BaseNotificationContentType & Partial<WithActions>)
  | (BaseNotificationContentType & WithActions);

const notificationVariants = tv({
  slots: {
    wrapperBorder:
      "sm:hidden flex h-max w-[320px] max-w-[320px] rounded-lg  shadow-[0px_2px_6px_-1px_#21212126,_0px_8px_24px_-4px_#2121214D] justify-end",
    icon: "h-5 w-5",
  },
  variants: {
    color: {
      success: {
        wrapperBorder: "bg-success",
        icon: "text-success",
      },
      danger: {
        wrapperBorder: "bg-danger",
        icon: "text-danger",
      },
      primary: {
        wrapperBorder: "bg-primary",
        icon: "text-primary",
      },
      warning: {
        wrapperBorder: "bg-warning",
        icon: "text-warning",
      },
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

const ICONS: Record<NotificationColor, ElementType> = {
  primary: InfoIcon,
  warning: InfoIcon,
  danger: CircleSlashIcon,
  success: CheckCircleIcon,
};

export const NotificationContent = ({
  closeButtonProps,
  state,
  titleProps,
  toast: {
    content: { actionLeft, actionRight, color, message, onClose, title, type },
    key,
  },
}: ToastNotificationProps<NotificationContentType> &
  Pick<ToastAria, "closeButtonProps" | "titleProps">) => {
  const { icon, wrapperBorder } = notificationVariants({
    color,
  });

  const Icon = ICONS[color];

  return (
    <div
      className={wrapperBorder()}
      data-testid={`notification-${type}-${key}`}
    >
      <div className="flex min-h-[132px] w-[98%] rounded-l-sm rounded-r-lg bg-white pl-3 pr-4 text-small">
        <div className="flex w-[13%] py-4">
          <Icon className={icon()} />
        </div>
        <div className="flex h-full w-[77%] flex-col justify-between py-4">
          <div className="flex flex-col">
            <h2 className="pb-2 font-semibold" {...titleProps}>
              {title}
            </h2>
            <p className="text-gray-600" {...titleProps}>
              {message}
            </p>
          </div>
          <div className="flex pt-6">
            {actionLeft && (
              <Button
                color="primary"
                variant="faded"
                size="sm"
                {...actionLeft}
                onClick={(e) => {
                  actionLeft.onClick?.(e);
                  state.close(key);
                }}
              >
                {actionLeft.title}
              </Button>
            )}
            {actionRight && (
              <Button
                color="primary"
                variant="faded"
                size="sm"
                className="ml-4"
                {...actionRight}
                onClick={(e) => {
                  actionRight.onClick?.(e);
                  state.close(key);
                }}
              >
                {actionRight.title}
              </Button>
            )}
          </div>
        </div>
        <div className="flex w-[10%] py-3">
          <Button
            data-testid={`close-notification-${key}`}
            {...closeButtonProps}
            onPress={() => {
              onClose?.();
              state.close(key);
            }}
            size="xs"
            color="primary"
            variant="light"
            isIconOnly
            id="close-notification"
          >
            <CloseIcon className="h-5 w-5 [&>svg]:!stroke-lg" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const Notification = ({
  state,
  ...props
}: ToastNotificationProps<NotificationContentType>) => {
  const ref = useRef(null);
  const { closeButtonProps, contentProps, titleProps, toastProps } = useToast(
    props,
    state,
    ref,
  );

  return (
    <div ref={ref} {...toastProps}>
      <div {...contentProps}>
        <NotificationContent
          state={state}
          closeButtonProps={closeButtonProps}
          titleProps={titleProps}
          toast={props.toast}
        />
      </div>
    </div>
  );
};

Notification.displayName = "RecareUI.Notification";
